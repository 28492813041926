// eslint-disable-next-line import/prefer-default-export
export const markers = {
  namespaced: true,
  state: {
    markers: [],
  },
  mutations: {
    setMarkers(state, data) {
      state.markers = data
    },
    addMarker: (state, marker) => {
      state.markers = [marker, ...state.markers]
    },
    updateMarker: (state, marker) => {
      state.markers = state.markers.map(datum => {
        if (datum.id === marker.id) {
          return marker
        }
        return datum
      })
    },
    removeMarker: (state, id) => {
      state.markers = state.markers.filter(datum => datum.id !== id)
    },
  },
  getters: {
    markers(state) {
      return state.markers
    },
  },
}
